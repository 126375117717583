<template>
  <div class="cart-display">
    <div class="cart-display_title">
      <button
        :disabled="getCartItemsCount == 0 || !getQueueIsEnd || redirecting"
        style="float: right"
        class="small outlined clear-btn"
        @click="clear">
        Clear
      </button>
      <h2>Cart</h2>
    </div>

    <div
      class="cart-display_content"
      :class="{
        'unable-checkout': unableToCheckout,
      }">
      <CartSummary :readOnly="redirecting" />

      <div class="unable-to-checkout" v-if="unableToCheckout">
        <h3><Icon :path="mdiClose" :size="22" /> Unable to Check Out</h3>
        <p>
          This may be due to an order being made for an event which has already
          finished
        </p>
      </div>

      <div v-if="service != null && !redirecting" class="check-out-button-wrap">
        <button @click="$emit('toggleCart')" class="back">
          <Icon :path="mdiChevronLeft" :size="22" /> Return to Menu
        </button>
        <button
          v-if="getCartItemsCount > 0"
          :disabled="
            getCartItemsCount == 0 || unableToCheckout || !getQueueIsEnd
          "
          @click="checkout"
          class="check-out">
          Complete Order
        </button>
      </div>

      <div class="loading-cart" v-if="redirecting">
        <LoadingSpinner />
        Please wait for a moment as we take you to your cart. You'll be there
        shortly!
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import router from "@/router";
  import LoadingSpinner from "@/components/LoadingSpinner";
  import { mapGetters } from "vuex";
  import CartSummary from "@/components/CartSummary";
  import { mdiClose, mdiChevronLeft } from "@mdi/js";

  export default {
    data() {
      return {
        serviceId: this.$route.params.serviceId,
        service: null,
        unableToCheckout: false,
        cartValid: true,
        initiallyValidatingOrder: false,
        redirecting: false,
        mdiClose,
        mdiChevronLeft,
      };
    },
    computed: {
      ...mapGetters({
        getCart: "cart/get",
        getQueueIsEnd: "cart/getQueueIsEnd",
        getCartOrderId: "cart/getOrderId",
        getCartId: "cart/getCartId",
        getCartKey: "cart/getCartKey",
        getCartServiceId: "cart/getServiceId",
        getCartItems: "cart/getItems",
        getCartItemsCount: "cart/getItemsCount",
        getCartSubTotal: "cart/getSubTotal",
        getCartServiceChargePercent: "cart/getServiceChargePercent",
        getCartServiceChargeAmount: "cart/getServiceChargeAmount",
        getCartTotal: "cart/getTotal",
        getCustomer: "cart/getCustomer",
        getItemName: "cart/getItemName",
        getItemPrice: "cart/getItemPrice",
        getCartComponents: "cart/getComponents",
        getSummary: "cart/getSummary",
      }),
    },
    methods: {
      async checkout() {
        const serviceId = this.serviceId;
        const cartId = this.getCartId;
        const cartKey = this.getCartKey;

        // for on-throttled connection
        this.redirecting = true;

        const cartValid = await store.dispatch("cart/validateCart");
        this.cartValid = cartValid;

        if (cartValid) {
          router.push({
            name: "checkout",
            params: {
              serviceId: serviceId,
              cartId: cartId,
              cartKey: cartKey,
            },
          });
          this.$emit("cartHidden");
        } else {
          this.$emit("refreshMenu");
        }
      },
      clear() {
        store.dispatch("cart/clear");
      },
    },
    components: {
      CartSummary,
      LoadingSpinner,
    },
    emits: ["refreshMenu", "toggleCart", "cartHidden"],
    mounted() {
      store
        .dispatch("repoServices/loadService", this.serviceId)
        .then((service) => {
          this.service = service;
          this.unableToCheckout =
            service.status === "Finished" || service.status === "Cancelled";
        })
        .then(async () => {
          this.initiallyValidatingOrder = true;
          this.cartValid = await store.dispatch("cart/validateCart");
          this.initiallyValidatingOrder = false;
        });
    },
  };
</script>

<style lang="scss" scoped>
  .cart-display {
    display: block;
    background: $col_white;
    border-left: 1px solid #ddd;
    min-height: 100%;
    height: 100%;
    width: 100%;
    padding: 5rem 1rem;
    position: fixed;
    top: 0;
    z-index: 10;
    height: 0;
    overflow-y: auto;
    transform: translateY(+100vh);
    transition: transform 0.15s;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);

    .cart-active & {
      transform: translateY(0);
    }

    &_title {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      height: 50px;

      h2 {
        margin-bottom: 0;
      }

      @media screen and (max-width: 960px) {
        margin-top: 4.5rem !important;
      }
    }

    &_content {
      position: relative;

      .tabletLayout & {
        padding-bottom: 70px !important;
      }
    }

    .tabletLayout & {
      &_title,
      &_content {
        width: 80%;
        max-width: 600px;
        margin: 0 auto;
      }
    }

    .mobileLayout & {
      &_title,
      &_content {
        width: 90%;
        margin: 0 auto;
      }
    }

    .mobileLayout &,
    .tabletLayout & {
      padding: 0;

      @media screen and (max-width: 480px) {
        padding: 0;
        height: 100%;
        min-height: auto;
      }
    }
  }

  @media only screen and (min-width: 961px) {
    .cart-display {
      position: fixed;
      width: 40%;
      right: 0;
      top: 0;
      bottom: 0;
      transform: translateY(0);
      transition: none;
      padding: 5rem 2rem 2rem;
    }
  }

  .empty-cart-message {
    padding: 5rem 0;
    text-align: center;
  }

  .cart-displaylist {
    display: flex;
    justify-content: space-between;
  }

  .cart-displaylist_item {
    display: flex;
    justify-content: space-between;
    margin: 0.25rem 0;
  }

  .cart-item-amount {
    display: flex;

    .count {
      width: 50px;
      height: 20px;
      text-align: center;
      font-size: 0.6rem;
      color: $col_gray;

      span {
        width: 30px;
        font-size: 1rem;
      }
    }

    .payment & {
      width: 50px !important;
    }
  }

  .cart-item-amount_alter {
    cursor: pointer;
    color: $col_beta-darker;
    margin: 0 0.25rem;
    height: 20px;
    width: 40px;

    .material-design-icon {
      height: 18px;
    }
  }

  .cart_price {
    width: 3rem;
  }

  .cart-item-component-alterations {
    color: $col_faded;
    font-size: 0.8rem;

    li {
      font-size: 0.75rem;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  button.clear-btn {
    max-width: 80px;
    min-width: auto !important;
    @include outlined-button($col: $col_beta-darker, $p: 1rem 0.5rem, $h: 2rem);
  }

  .check-out-button-wrap {
    button {
      &.check-out {
        @include contained-button($p: 1rem 0.5rem, $h: 4rem);
        width: 80%;
        max-width: 300px;
        min-width: auto;
        margin-top: 0;
      }

      &.back {
        @include button;
        background: #fff;
        color: $col_beta-darker;
        display: none;
      }
    }

    @media screen and (max-width: 960px) {
      border-top: 1px solid $col_beta-darker;

      button {
        display: flex !important;
        width: 50% !important;
        padding: 1rem 0.5rem !important;
        max-width: none !important;
        border-radius: 0 !important;

        @media screen and (max-width: 375px) {
          font-size: 0.875rem !important;
        }
      }
    }

    @media screen and (max-width: 960px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: fixed;
      bottom: 0px;
      left: 0;
      width: 100%;
    }
  }

  .unable-to-checkout {
    margin-bottom: 1rem;
    background: #ffe3e3;
    border-radius: 15px;
    padding: 0.75rem;

    .mobileLayout & {
      margin-bottom: 0;
    }

    h3 {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $col_beta-darker;
      font-size: 1rem;

      span.material-icons-outlined {
        position: relative;
        top: -2px;
      }
    }

    p {
      font-size: 0.8rem;
      margin-top: 0.2rem;
    }
  }

  .loading-cart {
    @include flex($ai: center, $jc: center, $g: 2rem);
    text-align: center;
    color: $col_softblack;
  }
</style>
