<template>
  <ul class="app-menu">
    <li class="breadcrumb">
      <a target="_blank" href="https://www.tucktrucks.co.uk/">
        <Icon :path="mdiWeb" />
        Tuck Trucks</a
      >
    </li>
  </ul>
</template>

<script>
  import { mdiWeb } from "@mdi/js";

  export default {
    data() {
      return {
        mdiWeb,
      };
    },
    props: {
      mobileLayout: {
        type: Boolean,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .theme--light {
    .app-menu {
      li {
        a {
          color: $col_black;
        }
        &:hover {
          background: $col_beta-lightest;
        }
      }
    }
  }

  .theme--dark {
    .app-menu {
      li {
        a {
          color: $col_white;
        }
        &:hover {
          background: $col_beta-darkest;
        }
      }
    }
  }

  .app-menu {
    li {
      border-bottom: 1px solid #ddd;
      padding: 0.5rem 1rem;

      a {
        text-decoration: none;

        display: flex;
        gap: 0.5em;
      }
    }
  }
</style>
