<template>
  <div class="menubar">
    <router-link class="logo-link" :to="getHomeRoute()">
      <img
        class="logo"
        alt="Tuck Trucks Logo"
        height="16"
        width="137"
        :src="this.getLogo()" />
    </router-link>
    <button
      v-if="showLocations"
      class="quicklink"
      :class="{ active: inArea('venues') || $route.path === '/' }"
      @click="this.navigate('venues')"
      :disabled="inArea('venues')">
      <Icon
        :path="mdiMapMarker"
        :size="20"
        title="Locations"
        :class="{ 'mobile-layout': mobileLayout }" />
      <span class="text">Locations</span>
    </button>
    <button
      v-if="showVendors"
      class="quicklink"
      :class="{ active: inArea('vendors') }"
      @click="this.navigate('vendors')"
      :disabled="inArea('vendors')">
      <Icon
        :path="mdiTruck"
        :size="20"
        title="Vendors"
        :class="{
          'mobile-layout': mobileLayout,
        }" />
      <span class="text">Vendors</span>
    </button>
    <span class="menu" @click="showModal = true"
      ><Icon
        :path="mdiMenu"
        :size="28"
        title="Menu"
        class="menu"
        :class="{ 'mobile-layout': mobileLayout }"
    /></span>

    <teleport to="body">
      <transition name="fade">
        <MenuDialog
          v-if="showModal"
          class="modal"
          :maxWidth="'450'"
          @close="showModal = false">
          <Breadcrumbs />
          <ul class="app-menu">
            <li>
              <a
                target="_blank"
                href="https://www.tucktrucks.co.uk/terms-of-service"
                >Terms and Conditions</a
              >
            </li>
            <li>
              <a target="_blank" href="https://tucktrucks.co.uk/privacy-policy/"
                >Privacy Policy</a
              >
            </li>
          </ul>
        </MenuDialog>
      </transition>
    </teleport>
  </div>
</template>

<script>
  import router from "@/router";
  import { mapGetters } from "vuex";
  import MenuDialog from "@/components/MenuDialog";
  import Breadcrumbs from "@/components/Breadcrumbs";
  import { mdiMenu, mdiMapMarker, mdiTruck } from "@mdi/js";

  export default {
    data() {
      return {
        showModal: false,
        mdiMenu,
        mdiMapMarker,
        mdiTruck,
      };
    },

    components: {
      MenuDialog,
      Breadcrumbs,
    },

    props: {
      mobileLayout: {
        type: Boolean,
      },
    },

    computed: {
      ...mapGetters({
        linkMode: "linkMode/getMode",
        linkRef: "linkMode/getRef",
      }),

      showLocations() {
        return this.linkMode == "default";
      },

      showVendors() {
        return this.linkMode == "default";
      },
    },

    methods: {
      navigate(name) {
        router.push({ name: name });
      },

      inArea(area) {
        return router.currentRoute.value.name == area;
      },

      getLogo() {
        return this.getCdnFile(
          `/images/logos/tuck-trucks/tt-logo-${
            window.theme.IsDark ? "white" : "black"
          }.png`
        );
      },

      getHomeRoute() {
        switch (this.linkMode) {
          default:
            return { path: "/" };
          case "vendor":
            return {
              name: "vendor",
              params: { vendorCode: this.linkRef },
            };
          case "venue":
            return {
              name: "venue",
              params: { venueCode: this.linkRef },
            };
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $topbar_height;
  }

  .menubar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    z-index: 1000;

    .logo-link {
      flex-grow: 1;
    }

    .quicklink {
      min-width: unset;
      flex-grow: 0;
      @include button($g: 0, $dir: column);
      padding: 0.25rem 0.5rem 0.15rem;
      background: transparent !important;
      border-radius: 0 !important;

      &.active {
        padding-bottom: 0.35rem;
        color: $col_beta-darker !important;
        border-bottom: 0.25rem solid $col_beta-darker !important;
        position: relative;
        top: 0.25rem;
      }

      .text {
        font-size: 0.65rem;
      }
    }

    @media only screen and (max-width: $mobile_max_width) {
      .quicklink {
        min-width: 40px;

        .mdi-icon {
          margin-right: 0;
        }

        .text {
          font-size: 0.6rem;
        }
      }
    }

    .menu {
      flex-grow: 0;
    }
  }

  .theme--light {
    .menubar {
      color: $col_black;
      background: $col_offwhite;
      box-shadow: 0 2px 5px $col_offwhite2;

      .quicklink {
        color: $col_black;
      }
    }
  }

  .theme--dark {
    .menubar {
      color: $col_white;
      background: $col_offblack;
      box-shadow: 0 2px 5px $col_offblack;

      .quicklink {
        color: $col_white;
      }
    }
  }

  .menu {
    cursor: pointer;
  }

  .logo-link {
    margin-top: 7px;
  }

  img.logo {
    height: 16px;
    padding: 0;
    margin: 0;
  }

  .app-menu {
    margin-top: 2rem;
  }
</style>
