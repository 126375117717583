<template>
  <div class="modal-mask" @click.prevent="close">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        @click.stop=""
        :style="cssProps"
        :class="{ verticalLayout: verticalLayout }">
        <div class="close-modal" @click.stop="close">
          <Icon :path="mdiClose" :size="24" />
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import { mdiClose } from "@mdi/js";

  export default {
    data() {
      return {
        mdiClose,
      };
    },
    props: {
      maxWidth: {
        type: String,
      },
      verticalLayout: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["close"],
    methods: {
      close() {
        this.$emit("close");
      },
    },
    computed: {
      cssProps() {
        return {
          "--max-width": this.maxWidth + "px",
        };
      },
    },
  };
</script>

<style lang="scss" scoped></style>
