<template>
  <div
    class="app-container"
    :class="{
      mobileLayout,
      tabletLayout,
      'cart-active': cartActive,
    }">
    <TopBar class="topbar" :mobileLayout="mobileLayout" />

    <div class="main-content-container" :class="{ 'cart-view': cartView2 }">
      <router-view
        v-slot="{ Component }"
        :xlLayout="xlLayout"
        :mobileLayout="mobileLayout"
        :tabletLayout="tabletLayout"
        @cartVisible="cartView2 = true"
        @cartHidden="cartView2 = false">
        <transition name="route" mode="out-in">
          <component
            :is="Component"
            :key="menuKey"
            @refreshMenu="refreshMenu"></component>
        </transition>
      </router-view>
    </div>
    <div v-if="cartView2" class="cart-display-wrapper">
      <CartDisplay
        @cartHidden="cartView2 = false"
        @toggleCart="cartActive = !cartActive"
        @refreshMenu="refreshMenu" />
      <CartDisplayButton
        @toggleCart="cartActive = !cartActive"
        :cartActive="cartActive" />
    </div>

    <transition name="fade">
      <NetworkError v-if="this.hasNetworkError()" />
    </transition>
  </div>
</template>

<script>
  import TopBar from "@/components/TopBar.vue";
  import CartDisplayButton from "@/components/CartDisplayButton.vue";
  import CartDisplay from "@/components/CartDisplay.vue";
  import NetworkError from "@/components/Network/NetworkError.vue";
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        xlLayout: false,
        mobileLayout: true,
        tabletLayout: false,
        cartView: null,
        cartView2: null,
        cartActive: false,
        menuKey: 0,
      };
    },
    methods: {
      refreshMenu() {
        this.menuKey++;
      },
      checkScreen() {
        this.windowWidth = window.innerWidth;
        this.xlLayout = this.windowWidth >= 1920;
        this.tabletLayout = this.windowWidth >= 600 && this.windowWidth < 960;
        this.mobileLayout = this.windowWidth < 600;
      },
    },

    watch: {
      "$route.name": {
        handler: function (newName) {
          if (newName === "checkout") this.cartView2 = false;
        },
        deep: true,
        immediate: true,
      },
    },

    created() {
      window.addEventListener("resize", this.checkScreen);
      this.cartView2 = this.$route.meta.cartView;
    },
    components: {
      TopBar,
      CartDisplayButton,
      CartDisplay,
      NetworkError,
    },
    computed: {
      ...mapGetters({
        hasNetworkError: "network/networkError",
      }),
    },
    mounted() {
      this.checkScreen();
      try {
        window.cookieconsent.initialise({
          container: document.body,
          palette: {
            popup: { background: "#ffe6e6" },
            button: { background: "#f19191" },
          },
          revokable: true,
          onStatusChange: function (/*status*/) {
            window.log.info(
              this.hasConsented() ? "[🍪] Enabled" : "[🍪] Disabled"
            );
          },
          law: {
            regionalLaw: false,
          },
          location: true,
        });
      } catch {
        window.log.warn(
          "[🍪] Failed to show cookie consent window, this is usually due to a cookie/popup/ad blocker."
        );
      }
    },
  };
</script>

<style lang="scss">
  .app-container {
    display: flex;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    position: relative;
    display: flex;
    &.mobileLayout {
      height: initial;
    }
    &.mobileLayout.cart-active {
      overflow: hidden;
      height: 100vh;
    }
  }

  .main-content-container {
    position: relative;
    width: 100%;
    padding: 4rem 0 3rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .theme--light {
    .main-content-container {
      background: $col_offwhite;
    }
  }
  .theme--dark {
    .main-content-container {
      background: $col_offblack2;
    }
  }

  @media only screen and (max-width: $mobileLayout_breakpoint_width) {
    .main-content-container {
      padding: 3.5rem 0 0;
    }
  }

  @media only screen and (max-width: $mobile_max_width) {
  }
  @media only screen and (min-width: 961px) {
    .main-content-container {
      padding: 3.5rem 0 0;
      &.cart-view {
        width: 60%;
      }
    }
  }

  /* Route transitions */
  .route-enter-from {
    opacity: 0;
  }
  .route-enter-active {
    transition: all 0.1s ease-out;
  }
  .route-leave-to {
    opacity: 0;
  }
  .route-leave-active {
    transition: all 0.1s ease-in;
  }
  .cart-display-wrapper {
    z-index: 16;
  }
</style>
