<template>
  <button
    v-if="getCartItemsCount"
    class="cart-display-button"
    @click="$emit('toggleCart')">
    <span v-if="!cartActive">
      <Icon :path="mdiCart" :size="20" class="cart-icon" /> View Cart ({{
        getCartItemsCount
      }})</span
    >
  </button>
</template>

<script>
  import { mapGetters } from "vuex";
  import { mdiCart } from "@mdi/js";

  export default {
    data() {
      return {
        serviceId: this.$route.params.serviceId,
        mdiCart,
      };
    },
    props: {
      cartActive: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({
        getCartItemsCount: "cart/getItemsCount",
      }),
    },
    mounted() {
      this.$store.dispatch("repoServices/loadService", this.serviceId);
    },
  };
</script>

<style lang="scss" scoped>
  .cart-display-button {
    @include contained-button($p: 0.85rem, $radius: 0);
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
    & > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      .material-icons-outlined {
        margin-right: 0.2rem;
      }
    }

    &:hover {
      background: $col_beta-darker;
      color: $col_white;
    }
    .cart-active & {
      display: none;
    }
  }

  .cart-icon {
    margin-right: 0.25rem;
  }

  @media only screen and (min-width: 961px) {
    .cart-display-button {
      display: none;
    }
  }
</style>
